import React, { useState, useRef, useEffect } from 'react';
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { Container } from 'react-bootstrap';

const DesignSlider = ({ title, children }) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      slide(300);
    }, 3000);

    setTimer(interval);

    return () => clearInterval(interval);
  }, []);

  const slide = (shift) => {
    scrl.current.scrollBy({
      left: shift,
      behavior: 'smooth'
    });

    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    // Check if slider reaches the end
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setScrollEnd(true);
      resetSlider(); // Call resetSlider function when end is reached
    } else {
      setScrollEnd(false);
    }
  };

  const resetSlider = () => {
    // Reset slider to the beginning
    scrl.current.scrollTo({
      left: 0,
      behavior: 'smooth'
    });
    setscrollX(0);
    setScrollEnd(false);
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  return (
    <Container fluid className='my-3 py-3 item-slider-container'>
      <h4 className='px-3 mb-3 item-title'>{title}</h4>
      <div className='item-slider'>
        <div onClick={() => slide(-300)} className={`left-arrow-left ${(scrollX < 1) ? 'is-disabled-hide' : ''}`}>
          <MdArrowBackIos size="70px" />
        </div>
        <div ref={scrl} onScroll={scrollCheck} className="item-container">
          {children}
        </div>
        <div className={`right-arrow-right ${(!scrollEnd) ? '' : 'is-disabled-hide'}`} onClick={() => slide(+300)}>
          <MdArrowForwardIos size="70px" />
        </div>
      </div>
    </Container>
  );
};

export default DesignSlider;
