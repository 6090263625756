import React from 'react';
import { Image, HStack, Box, Link, Heading, Text, Button, Checkbox,  Icon, createIcon, Input, Textarea } from '@chakra-ui/react'
import trusted_brand_img from '../assets/images/trusted_brand_img.png'
import trusted_brand_img2 from '../assets/images/trusted_brand_img2.png'
import trusted_brand_img3 from '../assets/images/trusted_brand_img3.png'
import trusted_brand_img4 from '../assets/images/trusted_brand_img4.png'
import ufone_logo_img2 from '../assets/images/ufone_logo_img2.png'
import not_sure_img from '../assets/images/not_sure_img.png'
import owner_photo from '../assets/images/owner_photo.png'
import Ellipse from '../assets/images/Ellipse 3.png'
import image from '../assets/images/image 1.png'
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { CheckIcon, StarIcon } from '@chakra-ui/icons'
import {InputText,Form,SelectField} from '../components/form'
import asim_jafri_ceo from '../assets/images/asim_jafri_ceo.png'

function Pricing(){
	return(
		<>
			<Header />

			<Box className="not_sure custom_container">
				<Box className="pricing_plan">
					<Heading className="main_heading">Managed Services</Heading>

					<Text className="let">Take your marketing campaigns to the next level with Woosh’s Managed Services. Contact us today to learn more about our offerings and how we can help you achieve your marketing goals</Text>


					<Button className="btan">Get a Quote</Button>
				</Box>

				<Box className="images">
					<Image src={not_sure_img} />
				</Box>
			</Box>

			<Box className="partner_section">
				<Box className="custom_container">
					<Box className="text_section">
						<Heading className="main_heading">Partner with Us!</Heading>
						<Text>Ready to revolutionize your digital marketing strategies with Woosh? Partner with us and unlock the power of immersive storytelling. Contact us today to learn more about Woosh and how we can help you achieve your marketing goals</Text>
						<Box mb="50px">
							<Input className="partner_field_section" placeholder='name@company.com' />
						</Box>

						<Button className="btn_primary">Join Now</Button>
					</Box>
				</Box>
			</Box>

			<Box className="custom_container">
				<HStack className="pricing_form_section" justifyContent="space-between">
					<Box className="text_section">
						<Heading className="main_heading" textAlign="left">Reach out and Hi five</Heading>
						<Text textAlign="left !important">Got queries or craving some Woosh wisdom? Give us a shout and let's unravel how Woosh can sprinkle some magic on your business or untangle those tech knots.</Text>
					</Box>

					<Box className="form_bg_section">
						<Form>
							<HStack justifyContent="space-between">
								<Box w="48%">
									<InputText name="name" title="Name*"  />
								</Box>

								<Box w="48%">
									<InputText name="name" title="Surname*"  />
								</Box>
							</HStack>

							<HStack justifyContent="space-between">
								<Box w="48%">
									<InputText name="name" title="Email*"  />
								</Box>

								<Box w="48%">
									<InputText name="name" title="Phone"  />
								</Box>
							</HStack>

							<Box>
								<SelectField name="industry_id" title="Location"  />
							</Box>

							<Box>
								<Box className="field_section">
									<label>Message*</label>
									<Textarea placeholder='Here is a sample placeholder' />
								</Box>
							</Box>

							<Button className="btn_primary" w="100%">Submit</Button>
						</Form>
					</Box>
				</HStack>
			</Box>

			<Box className="custom_container">
				<HStack className="pricing_bottom_section">
					<Box className="text_section">
						<Text textAlign="left !important">81% say their teamwork is more efficient</Text>
						<Heading className="main_heading" textAlign="left">Now that we are using Woosh!
						we’re much more connected</Heading>

						<Box className="inner_para_section">
							<Text>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, cumsan et iusto odio dignissim qui blandit praesent luptatum.</Text>
						</Box>
					</Box>

					<Box className="image_section">
						<Image src={asim_jafri_ceo} />
					</Box>
				</HStack>
			</Box>

			<Box className="best-pkg pricing_page custom_container" display="none">
				<Box className="text_section">
					<Heading className="main_heading">Our Best Packages</Heading>

					<Text>Start creating stories for free, ad a plan to publish to your Domain</Text>
				</Box>

				<Tabs>
				  <TabList>
				    <Tab>Monthly</Tab>
				    <Tab>Yearly</Tab>
				  </TabList>

				  <TabPanels>
				    <TabPanel>
				      <Box className="main_card">
							<Box className="price_card_section mt_50">
								<Box className="head_section">
									<h1 className="first">Lake</h1>

									<Text className="Month_name"> <strong>3 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$9.99</strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>

							</Box>

							<Box className="price_card_section">
								<Box className="badge_text_section">
									<Text>Best Seller</Text>
								</Box>
								<Box className="head_section">
									<h1 className="first">River</h1>

									<Text className="Month_name"> <strong>5 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$24.99</strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>
							</Box>

							<Box className="price_card_section mt_50">
								<Box className="head_section">
									<h1 className="first">Sea</h1>

									<Text className="Month_name"> <strong>15 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$49.99 </strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>
							</Box>
						</Box>
				    </TabPanel>
				    <TabPanel>
				      <Box className="main_card">
							<Box className="price_card_section">
								<Box className="head_section mt_50">
									<h1 className="first">Lake</h1>

									<Text className="Month_name"> <strong>3 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$9.99</strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>

							</Box>

							<Box className="price_card_section">
								<Box className="badge_text_section">
									<Text>Best Seller</Text>
								</Box>
								<Box className="head_section">
									<h1 className="first">River</h1>

									<Text className="Month_name"> <strong>5 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$24.99</strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>
							</Box>

							<Box className="price_card_section mt_50">
								<Box className="head_section">
									<h1 className="first">Sea</h1>

									<Text className="Month_name"> <strong>15 Stories</strong> / month</Text>
								</Box>

								<Box className="box">
									<Box className="vecter">
										<CheckIcon /> <Text>Story Builder</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Contacts Management</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Broadcast via SMS</Text>
									</Box>

									<Box className="vecter">
										<CheckIcon /> <Text>Twillio Integration</Text>
									</Box>
								</Box>

								<Box className="bottom">
										<Text className="mrg"> <strong className="strong">$49.99 </strong> / month</Text>
										<Text className="mrg1">Save 10% on Annual Payment</Text>

										<Button className="btan">Start Free Trial</Button>
								</Box>
							</Box>
						</Box>
				    </TabPanel>
				  </TabPanels>
				</Tabs>
			</Box>

			<Box className="trusted_by_section" display="none">
				<Box className="custom_container">
					<Heading className="main_heading" textAlign="center">Trusted By Over 5,000+ Brands Across 75+ Countries</Heading>

					<HStack>
						<Box className="box_img">
							<Image src={trusted_brand_img} />
						</Box>

						<Box className="box_img">
							<Image src={trusted_brand_img2} />
						</Box>

						<Box className="box_img">
							<Image src={trusted_brand_img3} />
						</Box>

						<Box className="box_img">
							<Image src={trusted_brand_img4} />
						</Box>
					</HStack>
				</Box>
			</Box>			

			<Box className="not_sure custom_container" display="none">
				<Box className="pricing_plan">
					<Heading className="main_heading">Not sure which pricing plan to pick?</Heading>

					<Text className="let">Let our product specialists help you pick a perfect plan according to your team, requirements and use case.</Text>


					<Button className="btan">Contact Us</Button>
				</Box>

				<Box className="images">
					<Image src={not_sure_img} />
				</Box>
			</Box>

			<Box className="main_leticia custom_container" display="none">
				<Box className="Leticia_Kutch">

					<Box>
						<Image src={owner_photo} margin="auto" />
					</Box>

					<h2>Leticia</h2>
					<Text className="ceo">CEO - Ufone</Text>

					<Box>
						<Text className="make_stories">MakeStories has been a productive and intuitive tool for Gallery Media Group. Our team was able to get up and running in a short time period within minimal setup and essentially no training.</Text>
					</Box>

					<Box>
				  		<Image src={ufone_logo_img2} margin="auto" />
					</Box>

					<Box className="Icons">
						<Text className="rating"> <strong>5.0</strong> /5.0 rating</Text>
						<HStack className="star_section">
							<Box>
								<StarIcon />
							</Box>

							<Box>
								<StarIcon />
							</Box>

							<Box>
								<StarIcon />
							</Box>

							<Box>
								<StarIcon />
							</Box>

							<Box>
								<StarIcon />
							</Box>
						</HStack>
					</Box>
				</Box>
			</Box>


			<Footer />
		</>
	)
}

export default Pricing;


