import React from 'react';
import { Image, HStack, Box, Link, IconButton, useDisclosure } from '@chakra-ui/react';
import footer_logo from '../assets/images/footer_logo.png';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

function HeaderPrice(){
	const { isOpen, onToggle } = useDisclosure();
	return(
		<>
			<Box className="header_price desktop_section">
				<Box className="custom_container">
					<HStack justifyContent="space-between">
						<Box>
							<Link href="/">
								<Image src={footer_logo} />
							</Link>
						</Box>

						<Box>
							<HStack>
								<Box>
									<Link href="/pricing" className="header_link">Pricing</Link>
								</Box>

								

								<Box>
									<Link href="/register" className="header_button_link">Sign up</Link>
								</Box>
							</HStack>
						</Box>
					</HStack>
				</Box>
			</Box>

			{/*Mobile Section*/}

			<Box className="header_price mobile_section">
                <Box className="custom_container">
                    <HStack justifyContent="space-between">
                        <Box>
                            <Link href="/">
								<Image src={footer_logo} />
							</Link>
                        </Box>

                        {/* Mobile Menu Toggle Button */}
                        <Box display="block">
                            <IconButton
                                aria-label={isOpen ? 'Close menu' : 'Open menu'}
                                icon={isOpen ? <HamburgerIcon /> : <HamburgerIcon />}
                                onClick={onToggle}
                            />
                        </Box>
                    </HStack>

                    <Box display={{ base: isOpen ? 'block' : 'none' }}>
                        <Box>
                            <Box>
                                <Link href="/pricing" className="header_link">Pricing</Link>
                            </Box>

                            <Box>
                                <Link href="/register" className="header_link">Sign up</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
		</>
	)
}

export default HeaderPrice;