import React from 'react';
import { HStack, Box, Image, Text,Heading,Checkbox,Button,Textarea,Link } from '@chakra-ui/react';
import Header from "../components/Header";
import {InputText,Form,SelectField} from '../components/form'
import contact_crew_image from '../assets/images/contact_crew_image.png'
import linkedin_logo from '../assets/images/contact_img/linkedin.png'
import whatsapp_logo from '../assets/images/contact_img/whatsapp.png'
import facebook_logo from '../assets/images/contact_img/Facebook.png'
import instagram_logo from '../assets/images/contact_img/instagram.png'
import youtube_logo from '../assets/images/contact_img/youtube.png'
import twitter_logo from '../assets/images/contact_img/twitter.png'
import contact_dive_img1 from '../assets/images/contact_dive_img1.png'
import contact_dive_img2 from '../assets/images/contact_dive_img2.png'
import contact_dive_img3 from '../assets/images/contact_dive_img3.png'
import Footer from "../components/Footer";

function ContactPage(){
	return(
		<>
			{/*Desktop Section*/}

			<Box className="desktop_section">
				<Header />
				<Box className="contact_page">
					<Box className="custom_container">
						<Box className="contact_banner_section">
							<Box className="text_section">
								<Heading className="main_heading" textAlign="left">Reach Out and High-Five!</Heading>
								<Text textAlign="left !important">Got queries or craving some Woosh wisdom? Give us a shout and let's unravel how Woosh can sprinkle some magic on your business or untangle those tech knots.</Text>
							</Box>

							<Box className="banner_bg_section">
								<Form>
									<HStack justifyContent="space-between">
										<Box w="48%">
											<InputText name="name" title="Name*" placeholder="Enter your name"  />
										</Box>

										<Box w="48%">
											<InputText name="name" title="Surname*" placeholder="Enter your surname"  />
										</Box>
									</HStack>

									<HStack justifyContent="space-between">
										<Box w="48%">
											<InputText name="name" title="Business Name*" placeholder="Enter your business name"  />
										</Box>

										<Box w="48%">
											<InputText name="name" title="Company Email*" placeholder="name@company.com"  />
										</Box>
									</HStack>

									<HStack justifyContent="space-between">
										<Box w="48%">
											<SelectField name="industry_id" title="Company Location"  />
										</Box>

										<Box w="48%">
											<SelectField name="industry_id" title="How Did you hear about us?*"  />
										</Box>
									</HStack>

									<Box>
										<Box className="field_section">
											<Textarea placeholder='Here is a sample placeholder' />
										</Box>
									</Box>

									<Button className="btn_primary" w="100%">Submit</Button>
								</Form>
							</Box>
						</Box>

						<Box className="contact_crew_section">
							<Box className="text_section">
								<Heading className="main_heading">Join the Woosh Crew!</Heading>
								<Text>Looking for a gig that's more high-five than handshake? Come aboard our ship of opportunity, where we mix work and play for a life that's all sorts of awesome.</Text>
								<Link>Work With Us</Link>
							</Box>

							<Image src={contact_crew_image} />
						</Box>

						<Box className="contact_connect_section">
							<Box className="text_section">
								<Heading className="main_heading">Connect With Us On</Heading>
							</Box>	

							<HStack justifyContent="center">
								<Box className="connect_box_section">
									<Image src={linkedin_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={whatsapp_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={facebook_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={instagram_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={youtube_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={twitter_logo} />
								</Box>
							</HStack>
						</Box>

						<Box className="contact_power_section">
							<Box className="text_section">
								<Heading className="main_heading">Let's Power Up Your Team</Heading>
								<Text>Training sessions, but make it fun! Ping us for some online sessions that'll turn your team into publishing superheroes. Think less snooze, more bam! pow! Kapow!</Text>
							</Box>

							<HStack justifyContent="center">
								<Box>
									<Button className="btn_primary">Sign Up</Button>
								</Box>

								<Box>
									<Text>or</Text>
								</Box>

								<Box>
									<Button className="btn_primary">Book a Demo</Button>
								</Box>
							</HStack>
						</Box>

						<Box className="contact_dive_section">
							<Box className="text_section">
								<Heading className="main_heading">Dive into Woosh Wonders</Heading>
								<Text>See how Woosh is reshaping the branding game! Dive into our latest success stories and discover how we're crafting brand legends. Let's make your brand story legendary!</Text>
							</Box>

							<HStack justifyContent="center" mb="20px">
								<Box>
									<Image src={contact_dive_img1} />
								</Box>

								<Box>
									<Image src={contact_dive_img2} />
								</Box>

								<Box>
									<Image src={contact_dive_img3} />
								</Box>
							</HStack>

							<Link>View More</Link>
						</Box>
					</Box>
				</Box>
				<Footer />
			</Box>

			{/*Mobile Section*/}

			<Box className="mobile_section">
				<Header />
				<Box className="contact_page">
					<Box className="custom_container">
						<Box className="contact_banner_section">
							<Box className="text_section">
								<Heading className="main_heading">Reach Out and High-Five!</Heading>
								<Text>Got queries or craving some Woosh wisdom? Give us a shout and let's unravel how Woosh can sprinkle some magic on your business or untangle those tech knots.</Text>
							</Box>

							<Box className="banner_bg_section">
								<Form>
									<Box>
										<InputText name="name" title="Name*" placeholder="Enter your name"  />
									</Box>

									<Box>
										<InputText name="name" title="Surname*" placeholder="Enter your surname"  />
									</Box>

									<Box>
										<InputText name="name" title="Business Name*" placeholder="Enter your business name"  />
									</Box>

									<Box>
										<InputText name="name" title="Company Email*" placeholder="name@company.com"  />
									</Box>

									<Box>
										<SelectField name="industry_id" title="Company Location"  />
									</Box>

									<Box>
										<SelectField name="industry_id" title="How Did you hear about us?*"  />
									</Box>

									<Box>
										<Box className="field_section">
											<Textarea placeholder='Here is a sample placeholder' />
										</Box>
									</Box>

									<Button className="btn_primary" w="100%">Submit</Button>
								</Form>
							</Box>
						</Box>

						<Box className="contact_crew_section">
							<Box className="text_section">
								<Heading className="main_heading">Join the Woosh Crew!</Heading>
								<Text>Looking for a gig that's more high-five than handshake? Come aboard our ship of opportunity, where we mix work and play for a life that's all sorts of awesome.</Text>
								<Link>Work With Us</Link>
							</Box>

							<Image src={contact_crew_image} />
						</Box>

						<Box className="contact_connect_section">
							<Box className="text_section">
								<Heading className="main_heading">Connect With Us On</Heading>
							</Box>	

							<HStack justifyContent="center" flexWrap="wrap" justifyContent="space-between">
								<Box className="connect_box_section">
									<Image src={linkedin_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={whatsapp_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={facebook_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={instagram_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={youtube_logo} />
								</Box>

								<Box className="connect_box_section">
									<Image src={twitter_logo} />
								</Box>
							</HStack>
						</Box>

						<Box className="contact_power_section">
							<Box className="text_section">
								<Heading className="main_heading">Let's Power Up Your Team</Heading>
								<Text>Training sessions, but make it fun! Ping us for some online sessions that'll turn your team into publishing superheroes. Think less snooze, more bam! pow! Kapow!</Text>
							</Box>

							<Box>
								<Box mb="20px">
									<Button className="btn_primary">Sign Up</Button>
								</Box>

								<Box>
									<Button className="btn_primary">Book a Demo</Button>
								</Box>
							</Box>
						</Box>

						<Box className="contact_dive_section">
							<Box className="text_section">
								<Heading className="main_heading">Dive into Woosh Wonders</Heading>
								<Text>See how Woosh is reshaping the branding game! Dive into our latest success stories and discover how we're crafting brand legends. Let's make your brand story legendary!</Text>
							</Box>

							<HStack justifyContent="center" mb="20px">
								<Box>
									<Image src={contact_dive_img1} />
								</Box>

								<Box>
									<Image src={contact_dive_img2} />
								</Box>

								<Box>
									<Image src={contact_dive_img3} />
								</Box>
							</HStack>

							<Link>View More</Link>
						</Box>
					</Box>
				</Box>
				<Footer />
			</Box>
		</>
	)
}

export default ContactPage;