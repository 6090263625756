import React from 'react';
import HeaderPrice from '../components/HeaderPrice'
import Footer from "../components/Footer";
import { HStack, Box, Image, Text,Heading } from '@chakra-ui/react';
import {useLocation,Link } from 'react-router-dom';
function SignupSuccess(){
	let { state } = useLocation();
	return(
		<>
			<HeaderPrice />

			<HStack className="signup_success_page">

				<Box className="text_section">
					<Heading className="main_heading">Congratulation</Heading>

					<Text>Your Account has been Setup</Text>

					<Link className="btn_login" to={`https://${state.domain}.wooshstories.com`}>Login Into your Account</Link>
				</Box>

			</HStack>

			<Footer />
		</>
	)
}

export default SignupSuccess;