import React from 'react';
import './App.css';
import Home from './pages/Home.js';
import LandingPage from './pages/Landing.js';
import Pricing from './pages/Pricing.js';
import Register from './pages/Register.js';
import SignupSuccess from './pages/SignupSuccess.js';
import AboutPage from './pages/AboutPage.js';
import ContactPage from './pages/ContactPage.js';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <>
   <ChakraProvider>
        <BrowserRouter>
          <Routes>            
            <Route path="/coming" element={<LandingPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/signupsuccess" element={<SignupSuccess />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
