import React from 'react';
import { Box, Image, Input, Button, Link ,Text,HStack} from '@chakra-ui/react';
import Logo from '../images/logo.png'
import Arrow from '../images/arrow.png'


function App() {
  return (
    <>
      
      <Box className="coming_page">
        

        <Box className="text_section">
          <Link href="/register" className="Linked">Sign up</Link>

          <Image className="img-fluid" src={Logo} alt="Logo" />

          <Box className="head_section">
            <Text as="h1" size="xl">Get Ready to Be Whooshed!</Text>
            <Image className="img-fluid" src={Arrow} alt="Arrow" />
          </Box>

          <Text as="h3" size="lg">Something Exciting is Coming Soon!</Text>

          <Box className="field_section">
            <Input type="email" name="email" placeholder="Type your email" _placeholder={{ color: 'white' }} />
            <Button type="button" _hover={{ cursor: 'pointer' }}>Notify me!</Button>
          </Box>

          <Link href="#" className="privacy_link"> Privacy Policy</Link>
        </Box>
      </Box>
    </>
  );
}

export default App;
